@import './sass/variables';
@import '@fgb/portal-component-library/styles';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';
@import 'bootstrap/scss/bootstrap';

/* ELEMENT STYLING */
html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    @if $light-theme {
      background: transparent;
      color: black;
    } @else {
      background: #000;
      color: white;
    }
  }
}

.font-heading {
  font-family: $font-heading;
}

.font-size-18 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  font-weight: normal !important;
  text-transform: none;
  font-family: $font-heading;
}

.member-card {
  background: url('../src/assets/images/member-card-bg.jpg');
  background-size: cover;
}

/* Placeholders */

.no-items {
  .no-results-placeholder-title {
    font-family: $font-heading;
    font-size: 25px;
    font-weight: 400;
  }

  .no-results-placeholder-description {
    font-size: 0.75rem !important;
    color: $black;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $disabled-color !important;
  }
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

.nav-secondary {
  display: flex;
  align-items: center;
  .nav-item.active {
    @media (max-width: 991px) {
      width: 5%;
      height: 49px;
    }
  }
}

$primary: $primary-color;

/* BOOTSTRAP OVERRIDES */
$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'auction': $auction-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $tertiary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'mp-virtual-event': $events-color,
  'mp-product': $tertiary-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'ecash': $ecash-color,
  'predictor': $predictor-color,
  'poll': $poll-color,
  'quiz': $quiz-color,
  'affiliate': $primary-color,
  'transfer': $primary-color,
  'white': #ffffff,
  'black': #000000,
  'sold-out': #70707033,
  'coming-soon': #70707033,
  'limit-reached': #e03e3e33,
  'not-enough-points': #e03e3e33,
  'add-card': $primary-color,
  'active-card': $primary-color,
  'inactive-card': $disabled-color,
  'frozen-card': $frozen-color,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

$date-select-box-shadow: 0 0 0 0.2rem rgb(60 85 124 / 25%);

/* Form Colors */

.form-control {
  color: #000000;
}

/* Banner */

.banner {
  background: url('../src/assets/images/mobile-banner.jpg');
  background-color: $primary-color;
  background-size: cover !important;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  @media (min-width: 991px) {
    background: url('../src/assets/images/banner.jpg');
  }

  h1 {
    display: none;
    color: $white;
  }

  .banner-logo {
    content: url('../src/assets/images/logo-banner.png');
    height: 85px;
    @media (max-width: 991px) {
      height: 45px;
    }
  }
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner.jpg);
  background-size: cover;
  background-position: center;
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.filter-modal-dialog {
  display: flex;
  align-items: flex-end !important;
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

/* Placeholder Styling */

.no-items {
  .no-results-placeholder-icon {
    max-width: 200px;
    margin: 18px auto;

    svg {
      height: 75px;
    }

    &.material-icons-two-tone {
      font-size: 4rem;
      margin: 18px auto 0;
    }
  }

  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }

  &.no-results-placeholder-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-color;
    height: 50px;
    @media (max-width: 991px) {
      margin: 0px;
    }

    .no-results-placeholder {
      font-size: 0.75rem;
    }
  }
}

.increment-button,
.decrement-button {
  svg {
    height: 13px;
    width: 13px;
  }
}

.redeem-list-item-icons {
  font-size: 18px;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
  margin-bottom: 0 !important;
  --bs-alert-margin-bottom: 0 !important;
  p {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.nav.nav-secondary {
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;
    .icon,
    label {
      color: #707070;
    }
  }
}

@media (max-width: 991px) {
  .nav.nav-secondary .nav-item {
    height: 49px;
  }
}

.announcements-carousel {
  .owl-item {
    @media only screen and (min-width: 992px) {
      margin-left: 6px !important;
      margin-right: 2px !important;
      width: 944px !important;
    }
  }
  .owl-carousel .owl-stage-outer {
    padding-left: 0px !important;
  }
}

.announcements-carousel .owl-item {
  padding-left: 0 !important;
  @media only screen and (min-width: 992px) {
    margin-left: 7px !important;
    margin-right: 2px !important;
  }
}

fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
}

.register-panel {
  .container {
    > *:not(router-outlet) {
      display: flex;
      flex-direction: column;
      height: inherit;
    }
  }
}

.invalid-token-modal {
  max-width: 700px;

  .modal-content {
    min-height: 280px;
    padding: 15px;
  }
}

/** Games Page */
.games {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }

          .survey-container {
            &.bg-poll {
              @media (max-width: 991px) {
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

/** Scorecard Carousel */
.badge-carousel-div {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

/** Announcements */

.announcements-main-title {
  margin-top: 1.5rem;
}

.ecash-description-icon {
  .color-1,
  .color-2,
  .color-3 {
    color: $primary-color !important;
  }
}

/** Scorecards/Badges */

.badge-title {
  color: $black !important;
}

.bg-carousel {
  min-height: 290px;
}

.scorecard-name,
.scorecard-description-modal-name {
  font-size: 1rem;
  color: $primary-color;
}

.one-punchcard-name {
  font-size: 1rem;
}

.scorecard-locked-name {
  font-size: 1rem;
  margin-bottom: 5px;
  margin-top: 10px;
}

.scorecard-description-modal-congratulation-title {
  font-size: 1rem !important;
  color: $success-color !important;
}

.progress-bar {
  background-color: $primary-color;
}

.scorecard-locked-footer {
  color: $info-color;
  border: 1px solid #dedddd;
}

.scorecard-description-modal .scorecard-punch-text-model {
  margin: 0 15px;
}

.scorecard-prize-border {
  padding: 0 5px 0 10px;
}

/** Rewards */

.rewards-terms-label {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

fgbcl-lotto-details {
  .lotto-details-countdown {
    margin-bottom: 10px;
  }
}

.rewards-details-desc-terms .accordion-button:not(.collapsed) {
  color: $primary-color;
}

.confirmation-custom-field-value {
  line-break: anywhere;
}

/** Payment */

fgbcl-ecash-description {
  .d-flex.flex-row.justify-content-center.align-items-start {
    align-items: center !important;
  }
}

.add-card-label {
  text-transform: capitalize !important;
}

.btn.add-card .icon {
  font-size: 24px;
  @media (min-width: 991px) {
    margin-right: 0 !important;
  }
}

fgbcl-confirm-modal {
  h5.fw-bold.mb-0 {
    font-family: $font-default !important;
  }
}

.ecash-description-box .vertical-line {
  border-top-color: #dedddd;
  opacity: 1;
}

.payment-description-text-one {
  padding-right: 10px !important;
}

.payment-description-text-two {
  padding-left: 18px !important;
}

.country-and-regions .form-floating .form-select {
  height: auto !important;
}

/** Tertiary */

fgbcl-terms-and-conditions {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $font-default;
    font-weight: bold !important;
    text-transform: uppercase;
    color: $primary-color;
    font-size: 0.875rem;
  }
  div,
  p {
    font-size: 0.75rem !important;
  }
}

fgbcl-contact-us {
  .success-message {
    color: $success-color;
    font-size: 0.875rem;
    font-weight: bold;
  }
}

.faq-section-card-title {
  font-family: $font-default;
  font-size: 0.875rem;
  font-weight: bold;
}

.faq-section-card-description {
  font-size: 0.875rem;
}

fgbcl-earn-points-card {
  .col-9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

fgb-notification-button {
  .btn.show {
    border-color: transparent;
  }
}

/* Registration */

.btn-previous-step {
  border: none !important;
}

.registration-terms-and-condition h5 {
  margin-bottom: 20px;
}

.wrap-accept-box {
  padding-bottom: 0;
}

/* Requiring Bootstrap */
@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}
